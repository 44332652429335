
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantevPEPrnT4IMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as _91txId_93le2k05CP0CMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue?macro=true";
import { default as affiliateLqnrusNBIbMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilOKaNPbFOZ2Meta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantkf2FmACh0mMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoPjMYnwU4apMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserwwgwucy71wMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingotcYcJwH5nUMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogTPg0VznWqGMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodeb7Zos8iRizMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casino7sYsh5lbCHMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikSHSGfWIWDCMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as faqjvgnT6AHvqMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/faq.vue?macro=true";
import { default as _91slug_936p5vTULgnAMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as index1HeVNm8Jg0Meta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexgtMUf45ycoMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as ansvarligt_45spilH9a3h7nUpmMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue?macro=true";
import { default as beskederIqsGKn5eDgMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as biometrisERioAA6DDMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/biometri.vue?macro=true";
import { default as blokeringd5B28SH2OjMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/blokering.vue?macro=true";
import { default as _91reportId_93IrmQWwFdqPMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eidtEZu6wFv25Meta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as indbetalingsloftmeCkATvv1nMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue?macro=true";
import { default as nedkoelingoNmKsNxMsXMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue?macro=true";
import { default as ret_45profilgDxYHeComNMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue?macro=true";
import { default as sletning0ZBUFfrapEMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/sletning.vue?macro=true";
import { default as spaeringpJDoFggxRIMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/spaering.vue?macro=true";
import { default as kundeserviceMipurXjT7hMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appN4mYqBwytQMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45osKZ53MnFZxMMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginuEE6cxnRjGMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as pressehpP98V76ZiMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikC3mUqwPReuMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signup8OIxUXapimMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilT7QkFSadmFMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexQPcWCHWPgtMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtu7oDtjM0hwMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93oPfbgfiJdlMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvEwBtNpWAMyMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vinderevGClFCmPiHMeta } from "/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantevPEPrnT4IMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "payments-pending",
    path: "/payments/pending/:txId",
    meta: _91txId_93le2k05CP0CMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateLqnrusNBIbMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilOKaNPbFOZ2Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantkf2FmACh0mMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoPjMYnwU4apMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserwwgwucy71wMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingotcYcJwH5nUMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogTPg0VznWqGMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodeb7Zos8iRizMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casino7sYsh5lbCHMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikSHSGfWIWDCMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqjvgnT6AHvqMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/faq.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_936p5vTULgnAMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1HeVNm8Jg0Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexgtMUf45ycoMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-ansvarligt-spil",
    path: "/konto/ansvarligt-spil",
    meta: ansvarligt_45spilH9a3h7nUpmMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederIqsGKn5eDgMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-biometri",
    path: "/konto/biometri",
    meta: biometrisERioAA6DDMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/biometri.vue")
  },
  {
    name: "konto-blokering",
    path: "/konto/blokering",
    meta: blokeringd5B28SH2OjMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/blokering.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93IrmQWwFdqPMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eidtEZu6wFv25Meta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "konto-indbetalingsloft",
    path: "/konto/indbetalingsloft",
    meta: indbetalingsloftmeCkATvv1nMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue")
  },
  {
    name: "konto-nedkoeling",
    path: "/konto/nedkoeling",
    meta: nedkoelingoNmKsNxMsXMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue")
  },
  {
    name: "konto-ret-profil",
    path: "/konto/ret-profil",
    meta: ret_45profilgDxYHeComNMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue")
  },
  {
    name: "konto-sletning",
    path: "/konto/sletning",
    meta: sletning0ZBUFfrapEMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/sletning.vue")
  },
  {
    name: "konto-spaering",
    path: "/konto/spaering",
    meta: spaeringpJDoFggxRIMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/konto/spaering.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeserviceMipurXjT7hMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appN4mYqBwytQMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osKZ53MnFZxMMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginuEE6cxnRjGMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: pressehpP98V76ZiMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikC3mUqwPReuMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup8OIxUXapimMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    meta: gemte_45spilT7QkFSadmFMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexQPcWCHWPgtMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtu7oDtjM0hwMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93oPfbgfiJdlMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vinderevGClFCmPiHMeta || {},
    component: () => import("/data/gitlab-runner/builds/t1_vxUBjD/0/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]