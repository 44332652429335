import {
    EXPERIMENT_COOKIE_PASSWORD_STRENGTH,
    EXPERIMENT_PASSWORD_STRENGTH,
} from '@core/constants'

export default defineNuxtRouteMiddleware(() => {
    useExperiment({
        cookieName: EXPERIMENT_COOKIE_PASSWORD_STRENGTH,
        experimentName: EXPERIMENT_PASSWORD_STRENGTH,
        allowWrite: true,
    })
})
