<script setup lang="ts">
useHead(() => ({
    link: [
        {
            rel: 'manifest',
            href: '/pages/manifest.json',
        }
    ]
}))

onMounted(() => {
    // Unregister all service workers and clear caches for all users
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
                registration.unregister()
            })
        })
    }
    // Clear caches programmatically for all users
    caches.keys().then((cacheNames) => {
        return Promise.all(
            cacheNames.map((cacheName) => {
                return caches.delete(cacheName)
            })
        )
    })
})
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>
